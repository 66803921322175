// @flow

import * as React from "react";
import { compose, withHandlers } from "recompose";
import { gql } from "@apollo/client";
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from "found";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import config from "../../config";

import type { HOC } from "recompose";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(3)
  }
});

const enhancer: HOC<*, *> = compose(
  withRouter,
  graphql(gql`
    query {
      viewer {
        id
        isAuthenticated
      }
    }
  `),
  withHandlers({
    handleSignInButtonClick: () => () => {
      window.location = `${config.getFlynetCoreLoginUrl()}?client_id=${encodeURIComponent(config.getFlynetCoreClientId())}&return_to=${encodeURIComponent(window.location.href)}`;
    }
  }),
  withStyles(styles)
);

const HomePageApp = ({ classes, data, handleSignInButtonClick, router }) => {
  if (!data || data.loading) {
    return <Typography>Loading...</Typography>;
  }

  if (data.error) {
    return <Typography>Error!</Typography>;
  }

  if (data.viewer.isAuthenticated) {
    router.push("/apps");
    return null;
  }

  return (
    <div className={classes.container}>
      <Button onClick={handleSignInButtonClick} color="primary">
        Sign in
      </Button>
    </div>
  );
};

export default enhancer(HomePageApp);
