// @flow

import * as React from "react";
import { ThemeContextConsumer } from "../../application/themeContext";
import { alpha } from "@mui/material/styles";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withStyles } from "@mui/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppBar from "@mui/material/AppBar";
import BrightnessMediumIcon from "@mui/icons-material/BrightnessMedium";
import Button from "@mui/material/Button";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import TabNavigator from "../TabNavigator";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import config from "../../application/config";

import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +backButton: string,
    +root: string
  |},
  +menuItems?: React.ChildrenArray<React.Element<typeof MenuItem>>,
  +onRequestBack: () => void,
  +onSearch?: (query: string) => void,
  +onTabSelected?: (selection: string) => void,
  +tabItems?: $ReadOnlyArray<string>,
  +title: string
|};

type State = {|
  anchorElement: Object,
  menuIsVisible: boolean,
  searchQuery: ?string,
  selectedTab: string
|};

const styles = theme => {
  const contrastText = theme.palette.getContrastText(
    theme.components.MuiAppBar.styleOverrides.colorPrimary.backgroundColor
  );
  return {
    appBar: {
      zIndex: 1200
    },
    backButton: {
      color: contrastText,
      marginRight: 20,
      marginLeft: -12
    },
    currentViewer: {
      marginLeft: theme.spacing(1)
    },
    menuTextSpacing: {
      marginLeft: theme.spacing(1.5)
    },
    menu: {
      zIndex: 1201
    },
    title: {
      color: contrastText,
      flexGrow: 1
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(1),
        width: "auto"
      }
    },
    searchIcon: {
      width: theme.spacing(9),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    inputRoot: {
      color: "inherit",
      width: "100%"
    },
    inputInput: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(10),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: 120,
        "&:focus": {
          width: 200
        }
      }
    }
  };
};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("TabbedAppBar"),

  withStyles(styles),

  graphql(query),

  withStateHandlers(
    ({ tabItems }) =>
      ({
        anchorElement: null,
        menuIsVisible: false,
        searchQuery: null,
        selectedTab: tabItems ? tabItems[0] : ""
      }: State),
    {
      setAnchorElement: () => (anchorElement: Object) => ({
        anchorElement
      }),

      setMenuIsVisible: () => (menuIsVisible: boolean) => ({
        menuIsVisible
      }),

      setSearchQuery: () => (searchQuery: string) => ({
        searchQuery
      }),

      setSelectedTab: () => (selectedTab: string) => ({
        selectedTab
      })
    }
  ),

  withHandlers({
    handleBackButtonClick:
      ({ onRequestBack }) =>
      () => {
        onRequestBack();
      },

    handleMenuButtonClick:
      ({ setAnchorElement, setMenuIsVisible }) =>
      (event: SyntheticInputEvent<HTMLInputElement>) => {
        setAnchorElement(event.target);
        setMenuIsVisible(true);
      },

    handleMenuRequestClose:
      ({ setMenuIsVisible }) =>
      () => {
        setMenuIsVisible(false);
      },

    handleSearchQueryChange:
      ({ setSearchQuery }) =>
      event => {
        setSearchQuery(event.target.value);
      },

    handleSearchQuerySubmit:
      ({ onSearch, searchQuery }) =>
      event => {
        event.preventDefault();
        if (onSearch) {
          onSearch(searchQuery);
        }
      },

    handleSignOutButtonClick: () => () => {
      window.location =
        config.getFlynetCoreLogoutUrl() +
        "?client_id=" +
        config.getFlynetCoreClientId() +
        "&return_to=" +
        window.location.origin;
    },

    handleTabNavigatorTabSelected:
      ({ onTabSelected, setSelectedTab }) =>
      (selectedTab: string) => {
        onTabSelected(selectedTab);
        setSelectedTab(selectedTab);
      }
  })
);

const ITEM_HEIGHT = 48;

const TabbedAppBar = ({
  anchorElement,
  classes,
  data,
  handleBackButtonClick,
  handleMenuButtonClick,
  handleMenuRequestClose,
  handleSearchQueryChange,
  handleSearchQuerySubmit,
  handleSignOutButtonClick,
  handleTabNavigatorTabSelected,
  menuIsVisible,
  menuItems,
  onSearch,
  selectedTab,
  tabItems,
  title,
  appBarBackgroundColor
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);
  return (
    <AppBar
      className={classes.appBar}
      style={
        appBarBackgroundColor ? { backgroundColor: appBarBackgroundColor } : {}
      }
      position="relative"
    >
      <Toolbar>
        <IconButton
          className={classes.backButton}
          aria-label="Back"
          onClick={handleBackButtonClick}
        >
          <ChevronLeft />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        {onSearch && (
          <div className={classes.search}>
            <form onSubmit={handleSearchQuerySubmit}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                onChange={handleSearchQueryChange}
              />
            </form>
          </div>
        )}
        {menuItems && menuItems.length > 0 && (
          <div>
            <IconButton
              aria-label="More"
              aria-owns={"app-bar-menu"}
              aria-haspopup="true"
              color="inherit"
              onClick={handleMenuButtonClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              className={classes.menu}
              id="app-bar-menu"
              anchorEl={anchorElement}
              open={menuIsVisible}
              onClose={handleMenuRequestClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: 200
                }
              }}
            >
              {menuItems.map((item, index) =>
                React.cloneElement(item, { key: index })
              )}
            </Menu>
          </div>
        )}
        <IconButton
          aria-haspopup="true"
          aria-owns={"app-bar-menu"}
          onClick={event => setAnchorEl(event.currentTarget)}
        >
          <AccountCircleIcon />
        </IconButton>
        <Menu
          className={classes.menu}
          id="app-bar-menu"
          anchorEl={anchorEl}
          keepMounted
          open={isOpen}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200
            }
          }}
        >
          <MenuItem>
            <AccountCircleIcon />
            <Typography className={classes.currentViewer}>
              {data && data.viewer && data.viewer.me.name}
            </Typography>
          </MenuItem>
          <MenuItem>
            <ThemeContextConsumer>
              {context =>
                context &&
                context.componentMounted && (
                  <React.Fragment>
                    <IconButton onClick={context.toggleTheme}>
                      <BrightnessMediumIcon color="action" />
                      {context.type === "light" && (
                        <Typography className={classes.menuTextSpacing}>
                          Toggle Dark Mode
                        </Typography>
                      )}
                      {context.type === "dark" && (
                        <Typography className={classes.menuTextSpacing}>
                          Toggle Light Mode
                        </Typography>
                      )}
                    </IconButton>
                  </React.Fragment>
                )
              }
            </ThemeContextConsumer>
          </MenuItem>
          <MenuItem>
            <Button fullWidth onClick={handleSignOutButtonClick}>
              <Typography align="center">Sign out</Typography>
            </Button>
          </MenuItem>
        </Menu>
      </Toolbar>
      {tabItems && tabItems.length > 0 && (
        <TabNavigator
          tabItems={tabItems}
          onRequestTabChange={handleTabNavigatorTabSelected}
          selectedTab={selectedTab ? selectedTab : tabItems[0]}
        />
      )}
    </AppBar>
  );
};

export default enhancer(TabbedAppBar);
