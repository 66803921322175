import { compose, setDisplayName } from "recompose";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withApollo } from "@apollo/client/react/hoc";
import CustomsShipmentPalletDetailScreen from "../CustomsShipmentPalletDetailScreen";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FindOrderProductionJobModal from "./components/FindOrderProductionJobModal";
import React, { useState } from "react";
import SelectOrderProductionJobModal from "./components/SelectOrderProductionJobModal";
import useDeferredPromise from "../../hooks/useDeferredPromise";
import withSnackbar from "../../components/withSnackbar";

import PendingStatusView from "../PendingStatusView";

const enhancer = compose(
  setDisplayName("NorthboundCustomsShipmentPalletDetailScreen"),

  withApollo,

  graphql(query, {
    options: ({ palletId }) => ({
      variables: {
        id: palletId
      },
      fetchPolicy: "network-only"
    })
  }),

  withSnackbar
);

const NorthboundCustomsShipmentPalletDetailScreen = props => {
  const { client, data, showErrorSnackbar, showSuccessSnackbar } = props;

  const { defer, deferRef } = useDeferredPromise();
  const { loading, error } = data;
  const [
    findOrderProductionJobModalIsOpen,
    setFindOrderProductionJobModalIsOpen
  ] = useState(false);
  const [
    selectOrderProductionJobModalIsOpen,
    setSelectOrderProductionJobModalIsOpen
  ] = useState(false);
  const [isShippingTransactionLoading, setIsShippingTransactionLoading] =
    useState(false);
  const [orderProductionJobOptions, setOrderProductionJobOptions] = useState(
    []
  );

  const handleFindOrderProductionJobModalRequestClose = () => {
    setFindOrderProductionJobModalIsOpen(false);
  };

  const handleFindOrderProductionJobModalComplete = data => {
    setFindOrderProductionJobModalIsOpen(false);
    deferRef.resolve(data);
  };

  const handleSelectOrderProductionJobModalRequestClose = () => {
    setSelectOrderProductionJobModalIsOpen(false);
  };

  const handleSelectOrderProductionJobModalComplete = data => {
    setSelectOrderProductionJobModalIsOpen(false);
    deferRef.resolve(data);
  };

  const handleRequestManualAddCarton = () => {
    setFindOrderProductionJobModalIsOpen(true);
    return defer().promise;
  };

  const handleBarcodeScan = barcode => {
    if (barcode) {
      const filteredBarcode = Array.from(barcode)
        .filter(char => {
          const result = char.charCodeAt(0) !== 29; // 29 is a group separator character for USPS (https://www.barcodefaq.com/usps-postal/usps-impb-gs1-128/)
          console.log(`${char}: ${char.charCodeAt(0)} (${result})`);
          return result;
        })
        .join("");
      console.log("filteredBarcode");
      console.log(filteredBarcode);
      const trackingNumber =
        filteredBarcode.trim().length > 22 &&
        filteredBarcode
          .trim()
          .substring(
            filteredBarcode.trim().length - 22,
            filteredBarcode.trim().length - 21
          ) === "9"
          ? filteredBarcode.substring(filteredBarcode.trim().length - 22)
          : filteredBarcode; // For USPS
      console.log("trackingNumber");
      console.log(trackingNumber);

      setIsShippingTransactionLoading(true);
      return client
        .query({
          query: gql`
            query shippingPackageByTrackingNumber($trackingNumber: String!) {
              shippingPackageByTrackingNumber(trackingNumber: $trackingNumber) {
                id
                trackingNumber
                shippingTransaction {
                  id
                  order {
                    id
                    includeCatalogInShipment
                    shippingTransactions {
                      id
                      packages {
                        id
                        customsShipmentPalletCartons {
                          id
                        }
                      }
                    }
                    productionJobs {
                      id
                      label
                      customizationAreas {
                        method
                      }
                      lineItemBreakdownByProduct {
                        product {
                          id
                          style
                        }
                        lineItems {
                          id
                          color {
                            id
                            name
                          }
                          sizes {
                            id
                            quantity
                            size {
                              id
                              code
                            }
                            gtin
                            countryOfOrigin
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: { trackingNumber },
          fetchPolicy: "network-only"
        })
        .then(({ data: { shippingPackageByTrackingNumber } }) => {
          if (shippingPackageByTrackingNumber) {
            const {
              id: shippingPackageId,
              shippingTransaction: {
                order: { productionJobs, shippingTransactions }
              }
            } = shippingPackageByTrackingNumber;
            if (
              shippingTransactions.filter(st => {
                return (
                  st.packages.filter(sp => {
                    return sp.customsShipmentPalletCartons.length > 0;
                  }).length > 0
                );
              }).length > 0
            ) {
              showSuccessSnackbar("Shipping transaction added to pallet!");
              return null;
            }

            // eslint-disable-next-line no-constant-condition
            if (false) {
              setOrderProductionJobOptions(productionJobs);
              setSelectOrderProductionJobModalIsOpen(true);
            } else {
              showSuccessSnackbar("Shipping transaction added to pallet!");
              return {
                shippingPackageId,
                lineItems: productionJobs.reduce(
                  (accumulator, productionJob) => {
                    return [
                      ...accumulator,
                      ...productionJob.lineItemBreakdownByProduct
                        .map(({ product: { style }, lineItems }) => {
                          return lineItems
                            .map(({ sizes, color: { name: color } }) => {
                              return sizes
                                .filter(({ quantity }) => quantity > 0)
                                .map(
                                  ({
                                    quantity,
                                    size: { code: size },
                                    gtin,
                                    countryOfOrigin
                                  }) => {
                                    return {
                                      identifier: gtin,
                                      countryOfOrigin: countryOfOrigin,
                                      description: `${style}-${color}-${size}`,
                                      embroidery: (productionJob.customizationAreas.some(area => area.method == 'EMBROIDERY')),
                                      quantity: quantity
                                    };
                                  }
                                );
                            })
                            .reduce(
                              (accumulator, value) => [
                                ...accumulator,
                                ...value
                              ],
                              []
                            );
                        })
                        .reduce(
                          (accumulator, value) => [...accumulator, ...value],
                          []
                        )
                    ];
                  },
                  []
                )
              };
            }
          } else {
            showErrorSnackbar(
              "Can't find package with that barcode, please try another barcode on the package."
            );
          }
        })
        .catch(e => {
          showErrorSnackbar(e.message);
        })
        .finally(() => setIsShippingTransactionLoading(false));
    } else {
      setFindOrderProductionJobModalIsOpen(true);
    }
    return defer().promise;
  };

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  return (
    <>
      <CustomsShipmentPalletDetailScreen
        {...props}
        onBarcodeScan={handleBarcodeScan}
        onRequestManualAddCarton={handleRequestManualAddCarton}
        hidePrintCartonLabel={true}
        suffix="-FG"
        includePrintMethodInSuffix={true}
        columns={[
          {
            label: "Tracking #",
            value: ({ id, shippingPackage }) =>
              shippingPackage ? shippingPackage.trackingNumber : id
          }
        ]}
      />
      <Dialog
        aria-describedby="Loading Shipping Transaction"
        aria-labelledby="Loading Shipping Transaction"
        open={isShippingTransactionLoading}
        fullWidth={true}
      >
        <DialogContent>
          <PendingStatusView status="Loading Shipping Transaction" />
        </DialogContent>
      </Dialog>
      <FindOrderProductionJobModal
        isOpen={findOrderProductionJobModalIsOpen}
        onRequestClose={handleFindOrderProductionJobModalRequestClose}
        onComplete={handleFindOrderProductionJobModalComplete}
      />
      {selectOrderProductionJobModalIsOpen && (
        <SelectOrderProductionJobModal
          isOpen={selectOrderProductionJobModalIsOpen}
          onRequestClose={handleSelectOrderProductionJobModalRequestClose}
          onComplete={handleSelectOrderProductionJobModalComplete}
          orderProductionJobOptions={orderProductionJobOptions}
        />
      )}
    </>
  );
};

export default enhancer(NorthboundCustomsShipmentPalletDetailScreen);
