import { compose, setDisplayName } from "recompose";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "./graph";
import { withApollo } from "@apollo/client/react/hoc";
import CustomsShipmentPalletDetailScreen from "../CustomsShipmentPalletDetailScreen";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FindOrderProductionJobModal from "./components/FindOrderProductionJobModal";
import React, { useState } from "react";
import SelectOrderProductionJobModal from "./components/SelectOrderProductionJobModal";
import useDeferredPromise from "../../hooks/useDeferredPromise";
import withSnackbar from "../../components/withSnackbar";

import PendingStatusView from "../PendingStatusView";

const { REACT_APP_NINJA_TRANSFERS_SHOPIFY_APP_URL } = process.env;

const enhancer = compose(
  setDisplayName("NorthboundCustomsShipmentPalletDetailScreen"),

  withApollo,

  graphql(query, {
    options: ({ palletId }) => ({
      variables: {
        id: palletId
      },
      fetchPolicy: "network-only"
    })
  }),

  withSnackbar
);

const NorthboundCustomsShipmentPalletDetailScreen = props => {
  const { client, data, showErrorSnackbar, showSuccessSnackbar } = props;

  const { defer, deferRef } = useDeferredPromise();
  const { loading, error } = data;
  const [
    findOrderProductionJobModalIsOpen,
    setFindOrderProductionJobModalIsOpen
  ] = useState(false);
  const [
    selectOrderProductionJobModalIsOpen,
    setSelectOrderProductionJobModalIsOpen
  ] = useState(false);
  const [isShippingTransactionLoading, setIsShippingTransactionLoading] =
    useState(false);
  const [orderProductionJobOptions, setOrderProductionJobOptions] = useState(
    []
  );

  const handleFindOrderProductionJobModalRequestClose = () => {
    setFindOrderProductionJobModalIsOpen(false);
  };

  const handleFindOrderProductionJobModalComplete = data => {
    setFindOrderProductionJobModalIsOpen(false);
    deferRef.resolve(data);
  };

  const handleSelectOrderProductionJobModalRequestClose = () => {
    setSelectOrderProductionJobModalIsOpen(false);
  };

  const handleSelectOrderProductionJobModalComplete = data => {
    setSelectOrderProductionJobModalIsOpen(false);
    deferRef.resolve(data);
  };

  const handleRequestManualAddCarton = () => {
    setFindOrderProductionJobModalIsOpen(true);
    return defer().promise;
  };

  const decodeTrackingNumberFromBarcode = barcode => {
    const trimmedBarcode = barcode.trim();

    const groupSeparatorIndex = trimmedBarcode.indexOf(String.fromCharCode(29));
    if (groupSeparatorIndex > -1) {
      return trimmedBarcode.slice(groupSeparatorIndex+1);
    } else {
      if(trimmedBarcode.length > 22 && trimmedBarcode.slice(-22, -21) === "9") {
        return trimmedBarcode.slice(-22);
      }
      // https://stackoverflow.com/questions/38188373/how-to-distinguish-ups-mail-innovations-from-usps-tracking-number
      if(trimmedBarcode.startsWith("420") && (trimmedBarcode.length === 30 || trimmedBarcode.length === 34 || trimmedBarcode.length === 38)) {
        return trimmedBarcode.slice(-26);
      }
    }
    return barcode;
  }

  const handleBarcodeScan = barcode => {
    if (barcode) {
      const trackingNumber = decodeTrackingNumberFromBarcode(barcode);
      console.log("trackingNumber");
      console.log(trackingNumber);

      setIsShippingTransactionLoading(true);
      return client
        .query({
          query: gql`
            query shippingPackageByTrackingNumber($trackingNumber: String!) {
              shippingPackageByTrackingNumber(trackingNumber: $trackingNumber) {
                id
                trackingNumber
                weight
                shippingTransaction {
                  id
                  shippingMethod {
                    name
                  }
                  order {
                    id
                    includeCatalogInShipment
                    shippingTransactions {
                      id
                      shippingMethod {
                        name
                      }
                      packages {
                        id
                        customsShipmentPalletCartons {
                          id
                        }
                        weight
                      }
                    }
                    productionJobs {
                      id
                      label
                      customizationAreas {
                        method
                      }
                      lineItemBreakdownByProduct {
                        product {
                          id
                          style
                        }
                        lineItems {
                          id
                          color {
                            id
                            name
                          }
                          sizes {
                            id
                            quantity
                            size {
                              id
                              code
                            }
                            gtin
                            countryOfOrigin
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: { trackingNumber },
          fetchPolicy: "network-only"
        })
        .then(({ data: { shippingPackageByTrackingNumber } }) => {
          if (shippingPackageByTrackingNumber) {
            const {
              id: shippingPackageId,
              weight: shippingPackageWeight,
              shippingTransaction: {
                shippingMethod: {
                  name: shippingMethodName
                },
                order: { productionJobs, shippingTransactions }
              }
            } = shippingPackageByTrackingNumber;
            if (
              shippingTransactions.filter(st => {
                return (
                  st.packages.filter(sp => {
                    return sp.customsShipmentPalletCartons.length > 0;
                  }).length > 0
                );
              }).length > 0
            ) {
              showSuccessSnackbar("Shipping transaction added to pallet! - (1)");
              return null;
            }

            // eslint-disable-next-line no-constant-condition
            if (false) {
              setOrderProductionJobOptions(productionJobs);
              setSelectOrderProductionJobModalIsOpen(true);
            } else {
              showSuccessSnackbar("Shipping transaction added to pallet! - (2)");
              return {
                shippingPackageId,
                lineItems: productionJobs.reduce(
                  (accumulator, productionJob) => {
                    return [
                      ...accumulator,
                      ...productionJob.lineItemBreakdownByProduct
                        .map(({ product: { style }, lineItems }) => {
                          return lineItems
                            .map(({ sizes, color: { name: color } }) => {
                              return sizes
                                .filter(({ quantity }) => quantity > 0)
                                .map(
                                  ({
                                    quantity,
                                    size: { code: size },
                                    gtin,
                                    countryOfOrigin
                                  }) => {
                                    return {
                                      carrierName: shippingMethodName, // TODO:
                                      weight: parseFloat(shippingPackageWeight)/parseFloat(lineItems.length),
                                      identifier: gtin,
                                      countryOfOrigin: countryOfOrigin,
                                      description: `${style}-${color}-${size}`,
                                      embroidery: (productionJob.customizationAreas.some(area => area.method == 'EMBROIDERY')),
                                      quantity: quantity
                                    };
                                  }
                                );
                            })
                            .reduce(
                              (accumulator, value) => [
                                ...accumulator,
                                ...value
                              ],
                              []
                            );
                        })
                        .reduce(
                          (accumulator, value) => [...accumulator, ...value],
                          []
                        )
                    ];
                  },
                  []
                )
              };
            }
          } else {
            return fetch(`${REACT_APP_NINJA_TRANSFERS_SHOPIFY_APP_URL}/production/shipment/${trackingNumber}`,)
              .then(response => {
                if(response.ok) {
                  return response
                    .json()
                    .then(data => ({ ...data, inventoryOrderCartonNumber: trackingNumber }));
                } else {
                  showErrorSnackbar(
                    "Can't find package with that barcode, please try another barcode on the package."
                  );
                  return null;
                }
              });
          }
        })
        .catch(e => {
          showErrorSnackbar(e.message);
        })
        .finally(() => setIsShippingTransactionLoading(false));
    } else {
      setFindOrderProductionJobModalIsOpen(true);
    }
    return defer().promise;
  };

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  return (
    <>
      <CustomsShipmentPalletDetailScreen
        {...props}
        onBarcodeScan={handleBarcodeScan}
        onRequestManualAddCarton={handleRequestManualAddCarton}
        hidePrintCartonLabel={true}
        suffix="-FG"
        includePrintMethodInSuffix={true}
        columns={[
          {
            label: "Tracking #",
            value: ({ id, shippingPackage, inventoryOrderCartonNumber }) =>
              shippingPackage ? shippingPackage.trackingNumber : inventoryOrderCartonNumber ? inventoryOrderCartonNumber : id
          }
        ]}
      />
      <Dialog
        aria-describedby="Loading Shipping Transaction"
        aria-labelledby="Loading Shipping Transaction"
        open={isShippingTransactionLoading}
        fullWidth={true}
      >
        <DialogContent>
          <PendingStatusView status="Loading Shipping Transaction" />
        </DialogContent>
      </Dialog>
      <FindOrderProductionJobModal
        isOpen={findOrderProductionJobModalIsOpen}
        onRequestClose={handleFindOrderProductionJobModalRequestClose}
        onComplete={handleFindOrderProductionJobModalComplete}
      />
      {selectOrderProductionJobModalIsOpen && (
        <SelectOrderProductionJobModal
          isOpen={selectOrderProductionJobModalIsOpen}
          onRequestClose={handleSelectOrderProductionJobModalRequestClose}
          onComplete={handleSelectOrderProductionJobModalComplete}
          orderProductionJobOptions={orderProductionJobOptions}
        />
      )}
    </>
  );
};

export default enhancer(NorthboundCustomsShipmentPalletDetailScreen);
