// @flow

import * as React from "react";
import { compose, setDisplayName } from "recompose";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import BarcodePivot from "../components/BarcodePivot";
import PendingStatusView from "../components/PendingStatusView";
import config from "./config";

import type { HOC } from "recompose";

type Props = {|
  +children: any
|};

const enhancer: HOC<*, Props> = compose(
  setDisplayName("Root"),
  graphql(gql`
    query {
      viewer {
        id
        isAuthenticated
      }
    }
  `)
);

const Root = props => {
  if (!props.data || props.data.loading) {
    return <PendingStatusView status="Authenticating" />;
  }

  if (!props.data.viewer || !props.data.viewer.isAuthenticated) {
    window.location = `${config.getFlynetCoreLoginUrl()}?client_id=${encodeURIComponent(config.getFlynetCoreClientId())}&return_to=${encodeURIComponent(window.location.href)}`;
    return null;
  }

  return <BarcodePivot>{props.children}</BarcodePivot>;
};

export default enhancer(Root);
